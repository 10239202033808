




























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  lineClamp = true;
  get product() {
    return this.$store.state.product.product;
  }
  mounted(): void {
    const length = this.product.fundamentals.description.length;
    this.lineClamp = length > 550;
  }
}
