






































































import { IPosition } from "@/core/types";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProductPosition extends Vue {
  get position(): IPosition {
    return this.$store.state.product.position;
  }
}
