


































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  ICurrentPrice,
  IPricesRes,
  ITimeIntervals,
  ProductUseCases,
} from "@/core/Product";
import { FavUseCases, getAssetURL, MarketStatus } from "@/core";
import { IAmount, IPosition, IProduct } from "@/core/types";
import ProductNews from "../components/product-detail/ProductNews.vue";
import ProductDividends from "../components/product-detail/ProductDividends.vue";
import ProductPricesChart, {
  IAssetMetadata,
} from "../components/product-detail/ProductPricesChart.vue";
import ProductMarketData from "../components/product-detail/ProductMarketData.vue";
import ProductPosition from "../components/product-detail/ProductPosition.vue";
import ProductAbout from "../components/product-detail/ProductAbout.vue";

@Component({
  components: {
    ProductNews,
    ProductDividends,
    ProductPricesChart,
    ProductPosition,
    ProductMarketData,
    ProductAbout,
  },
  beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.clearInterval(this.interval);
    next();
  },
})
export default class ProductView extends Vue {
  loading = true;
  p = {} as IProduct;
  logoUrl = "";
  intervals = [
    {
      label: "1D",
      value: "1d",
      text: "Día",
    },
    {
      label: "1S",
      value: "1w",
      text: "Semana",
    },
    {
      label: "1M",
      value: "1m",
      text: "Mes",
    },
    {
      label: "3M",
      value: "3m",
      text: "3 meses",
    },
    {
      label: "1A",
      value: "1y",
      text: "Año",
    },
    {
      label: "5A",
      value: "5y",
      text: "5 años",
    },
  ];
  activeInterval: ITimeIntervals = "1m";
  loadingPrices = true;
  loadingMarketData = true;
  marketData = {};
  ordersPosition = {};

  interval: number | undefined = undefined;

  get showVolume(): boolean {
    return this.$store.state.product.showVolume;
  }

  get showCandles(): boolean {
    return this.$store.state.product.showCandles;
  }
  get assetMetadata(): IAssetMetadata {
    return {
      name: this.p?.name,
      symbol: this.p?.symbol,
      logoUrl: this.logoUrl,
      isFav: this.p?.isFavorite || false,
    };
  }

  get EPSAmount(): IAmount {
    return {
      value: this.p?.fundamentals?.eps,
      currency: this.p?.currency,
    };
  }

  formatNum(num?: number): string {
    if (!num) {
      return "-";
    }
    return num.toFixed(2).replace(".", ",");
  }

  handleClickVolume(): void {
    this.$store.dispatch("product/onVolumeClick");
    if (this.showVolume) {
      this.$track({
        label: "assetedetails_graphic_click_selecttype",
        properties: {
          ...this.trackingEventPayload,
          graphic_type: "volume",
        },
      });
    } else {
      this.$track({
        label: "assetedetails_graphic_click_deselecttype",
        properties: {
          ...this.trackingEventPayload,
          graphic_type: "volume",
        },
      });
    }
  }
  handleClickCandles(): void {
    this.$store.dispatch("product/onCandlesClick");
    if (this.showCandles) {
      this.$track({
        label: "assetedetails_graphic_click_selecttype",
        properties: {
          ...this.trackingEventPayload,
          graphic_type: "candles",
        },
      });
    } else {
      this.$track({
        label: "assetedetails_graphic_click_deselecttype",
        properties: {
          ...this.trackingEventPayload,
          graphic_type: "candles",
        },
      });
    }
  }

  openFullPosition(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("../components/product-detail/ProductPositionModal.vue")
    );
    this.$store.dispatch("modal/show");
    this.$track({
      label: "assetdetails_mypositionwidget_click",
      properties: {
        ...this.trackingEventPayload,
      },
    });
  }

  openValuationHelpModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("../components/product-detail/ValuationHelpModal.vue")
    );
    this.$store.dispatch("modal/show");
  }

  openDividendHelpModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("../components/product-detail/DividendHelpModal.vue")
    );
    this.$store.dispatch("modal/show");
  }

  async fetchInterval(interval: ITimeIntervals): Promise<void> {
    this.loadingPrices = true;
    this.$store.dispatch("product/destroyChart");
    await this.$store.dispatch("product/fetchPrices", {
      interval,
      id: this.$route.params.id,
    });
    this.activeInterval = interval;
    this.loadingPrices = false;
  }

  startPolling(): void {
    if (!MarketStatus.open) return;

    this.interval = window.setInterval(() => {
      this.$store.dispatch("product/fetchCurrentPrice", this.$route.params.id);
    }, 5000);
  }

  trackReadMore(): void {
    this.$track({
      label: "assetdetails_aboutasset_clickmoreinfo",
      properties: {
        ...this.trackingEventPayload,
      },
    });
  }
  trackReadLess(): void {
    this.$track({
      label: "assetdetails_aboutasset_clicklessinfo",
      properties: {
        ...this.trackingEventPayload,
      },
    });
  }

  handleClickNews(n: any): void {
    this.$track({
      label: "assetdetails_news_clickitem",
      properties: {
        ...this.trackingEventPayload,
        source: n.source.name,
      },
    });
  }

  async setFav(): Promise<void> {
    if (this.p?.isFavorite) {
      await FavUseCases.unsetFav(this.p.id);
      this.p.isFavorite = false;
    } else {
      if (this.p?.id) {
        await FavUseCases.setFav(this.p.id);
        this.p.isFavorite = true;
      }
    }
  }

  async fetchProduct(): Promise<void> {
    this.loading = true;
    this.p = await ProductUseCases.getProduct(this.$route.params.id);
    this.logoUrl = (await getAssetURL(this.p.symbol)) as string;
    this.$store.commit("product/SET_PRODUCT", this.p);
    this.$store.commit("product/SET_LOGOURL", this.logoUrl);
    this.loading = false;
  }

  async fetchMarketData(): Promise<void> {
    this.loadingMarketData = true;
    const marketData = await ProductUseCases.getMarketData(
      this.$route.params.id
    );

    this.marketData = marketData;

    this.loadingMarketData = false;
  }

  async fetchPrices(): Promise<void> {
    this.loadingPrices = true;
    const pricesRes = await ProductUseCases.getPrices(
      this.$route.params.id,
      this.activeInterval
    );

    // this.prices = pricesRes;
    this.$store.commit("product/SET_PRICES", pricesRes);
    this.loadingPrices = false;
  }

  get trackingEventPayload() {
    return {
      asset_name: this.p?.symbol,
      asset_id: this.p?.id,
      hot_cold: this.p?.hot ? "HOT" : "COLD",
    };
  }

  get prices(): IPricesRes {
    return this.$store.state.product.prices;
  }

  get currentPrice(): ICurrentPrice {
    return this.$store.state.product.currentPrice;
  }

  async fetchProductOrdersPosition(): Promise<void> {
    const orders = await ProductUseCases.getOKOrders(this.$route.params.id);
    const filteredOrders = orders.filter((o) => o.status === "OK");
    this.$store.commit("product/SET_ORDERS", filteredOrders);
  }

  get position(): IPosition {
    return this.$store.state.product.position;
  }

  fetchPosition(): void {
    this.$store.dispatch("product/fetchPosition");
  }

  init(): void {
    this.$store.dispatch("product/main", this.$route.params.id);
    this.fetchProduct();
    this.fetchPrices();
    this.fetchMarketData();
    this.fetchProductOrdersPosition();
    this.$store.dispatch("product/fetchCurrentPrice", this.$route.params.id);
    this.startPolling();
  }

  mounted(): void {
    this.init();
  }

  get fullMarketData(): any {
    return {
      ...this.marketData,
      currentPrice: this.currentPrice,
      high52weeks: this.p?.fundamentals.high52Weeks,
      low52Weeks: this.p?.fundamentals.low52Weeks,
      volume: this.p?.fundamentals.volume,
      averageDailyVolumeLast12Months:
        this.p?.fundamentals.averageDailyVolumeLast12Months,
      percentPriceChange52Weeks: this.p?.fundamentals.percentPriceChange52Weeks,
      averageDailyVolumeLast4Weeks:
        this.p?.fundamentals.averageDailyVolumeLast4Weeks,
      percentPriceChange4weeks: this.p?.fundamentals.percentPriceChange4weeks,
      highPriceLast4weeks: this.p?.fundamentals.highPriceLast4weeks,
      lowPriceLast4weeks: this.p?.fundamentals.lowPriceLast4weeks,
    };
  }

  get intervalText(): string | undefined {
    return this.intervals.find((i) => i.value === this.activeInterval)?.text;
  }

  @Watch("$route.params.id")
  onRouteParamsIdChange(): void {
    this.init();
  }
}
