


















































import { Component, Vue } from "vue-property-decorator";
import { formatDate } from "@/core";
@Component({
  props: {
    news: Array,
  },
})
export default class ProductNews extends Vue {
  formatDate(d: string): string {
    return formatDate(d);
  }
}
