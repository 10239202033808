/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  currencyKey,
  formatAmount,
  formatVolume,
  getCurrencySymbol,
} from "@/core";
import { IFormattedPrice, ITimeIntervals } from "@/core/Product";
import {
  BarData,
  createChart,
  IChartApi,
  ISeriesApi,
  LineData,
} from "lightweight-charts";

interface loadChartParams {
  items: IFormattedPrice[];
  id: string;
  showVolume: boolean;
  showCandles: boolean;
  currency: currencyKey;
  interval: ITimeIntervals;
}

export type updatePriceFn = (data: LineData[]) => void;

export interface IChartInstance {
  chart: IChartApi;
  priceSeries: ISeriesApi<"Line">;
  volumeSeries: ISeriesApi<"Histogram">;
  updatePrice: updatePriceFn;
  showVolume: () => void;
  hideVolume: () => void;
  showCandles: () => void;
  hideCandles: () => void;
}
interface IHoverData {
  price: number;
  volume: number;
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
}

export function loadChart({
  id,
  items,
  currency = "USD",
  interval,
}: loadChartParams): IChartInstance {
  const el = document.getElementById(id);
  const width = el!.clientWidth;
  const height = 468;

  const chart = createChart(id, {
    width,
    height,
    timeScale: {
      borderVisible: false,
      visible: true,
      fixLeftEdge: true,
      fixRightEdge: interval === "1d" ? false : true,
      timeVisible: true,
    },
    rightPriceScale: {
      borderVisible: false,
    },
    handleScroll: true,
    handleScale: {
      mouseWheel: false,
      pinch: false,
    },
    crosshair: {
      horzLine: {
        labelVisible: false,
        visible: false,
      },
      vertLine: {
        labelVisible: false,
        visible: true,
      },
    },
    layout: {
      backgroundColor: "transparent",
      fontFamily: "Graphik",
      textColor: "#a7a7a7",
    },
    grid: {
      vertLines: {
        color: "#fff",
      },
      horzLines: {
        color: "rgb(229, 230, 235)",
      },
    },
  });

  // Make chart responsive
  new ResizeObserver((entries) => {
    if (entries.length === 0 || entries[0].target !== el) {
      return;
    }
    const newRect = entries[0].contentRect;
    // chart.applyOptions({ height: newRect.height, width: newRect.width });
    chart.resize(newRect.width, newRect.height, true);
  }).observe(el as Element);

  chart.timeScale();

  if (interval !== "1d") {
    chart.timeScale().fitContent();
  }

  const lineData = items.map(({ timestamp, value }) => ({
    time: timestamp,
    value,
  })) as LineData[];
  const volumeData = items.map(({ timestamp, volume }) => ({
    time: timestamp,
    value: volume,
  })) as LineData[];
  const candlesData = items.map((item) => {
    const { timestamp, open, high, low, close } = item;
    return {
      time: timestamp,
      open,
      high,
      low,
      close,
    };
  }) as BarData[];

  const priceSeries = chart.addLineSeries({
    lineWidth: 2,
    color: "#2eb561",
    lastValueVisible: false,

    priceFormat: {
      type: "custom",
      formatter: (value: number) =>
        `${value.toFixed(2)} ${getCurrencySymbol(currency)}`,
    },
  });
  const volumeSeries = chart.addHistogramSeries({
    visible: false,
    priceFormat: {
      type: "volume",
    },
    priceLineVisible: false,
    color: "rgba(46, 107, 239, 0.50)",
    priceScaleId: "",
    lastValueVisible: false,
    scaleMargins: {
      top: 0.8,
      bottom: 0,
    },
  });
  const candleSeries = chart.addCandlestickSeries({
    visible: false,
    upColor: "#2eb561",
    downColor: "#eb311a",
    wickDownColor: "#0d0d0d",
    wickUpColor: "#0d0d0d",
    borderVisible: false,
    lastValueVisible: false,
  });

  // Set initial data
  priceSeries.setData(lineData);
  candleSeries.setData(candlesData);
  volumeSeries.setData(volumeData);

  const toolTip = document.createElement("div");
  toolTip.className = "nn-floating-tooltip";
  el!.appendChild(toolTip);

  // Handle Hover Event

  chart.subscribeCrosshairMove((params) => {
    const price = params.seriesPrices.get(priceSeries);
    // Skipping type checking for this line since we can't spread generics in TS as of 24/02/2022
    // https://github.com/Microsoft/TypeScript/issues/10727
    // eslint-disable-next-line @typescript-eslint/ban-types
    const candle = params.seriesPrices.get(candleSeries) as {
      open: number;
      high: number;
      low: number;
      close: number;
    };
    const volume = params.seriesPrices.get(volumeSeries);
    const x = params.point?.x || 0;
    const y = params.point?.y || 0;

    const isHovering = x && y && params.time;

    const item = items.find((i) => i.timestamp === params.time);
    const event = new CustomEvent("hover:index", {
      detail: {
        price,
        item,
        time: params.time,
        volume: params.seriesPrices.get(volumeSeries) || 0,
      },
    });

    el?.dispatchEvent(event);

    if (!isHovering) {
      toolTip.style.display = "none";
      return;
    } else {
      const hoverData = {
        price,
        volume,
        open: toAmount(candle.open),
        close: toAmount(candle.close),
        high: toAmount(candle.high),
        low: toAmount(candle.low),
      } as unknown as IHoverData;

      if (volumeSeries.options().visible && !candleSeries.options().visible) {
        toolTip.style.display = "block";
        toolTip.innerHTML = `<div>
        Volumen: ${formatVolume(hoverData.volume as number)}
      </div>`;
      }
      if (candleSeries.options().visible) {
        toolTip.style.display = "block";
        toolTip.innerHTML = `<div class="grid grid-cols-2 w-36 gap-y-2">
        <span>Apertura:</span><span class="text-right">${hoverData.open}</span>
        <span>Cierre:</span><span class="text-right">${hoverData.close}</span>
        <span>Mínimo:</span><span class="text-right">${hoverData.low}</span>
        <span>Máximo:</span><span class="text-right">${hoverData.high}</span>
        ${
          volumeSeries.options().visible
            ? `<span>Volumen:</span><span class="text-right">${formatVolume(
                hoverData.volume
              )}</span>`
            : ""
        }
      </div>`;
      }

      const left = x;
      const top = y;
      const tooltipWidth = toolTip.clientWidth;
      const tooltipHeight = toolTip.clientHeight;
      const tooltipOffset = 12;

      toolTip.style.left = left - tooltipWidth - tooltipOffset + "px";
      toolTip.style.top = top - tooltipHeight - tooltipOffset + "px";
    }
  });

  // Update functions
  const updatePrice: updatePriceFn = (data) => {
    data.forEach((d) => {
      priceSeries.update(d);
    });
  };

  const seriesMap = {
    price: priceSeries,
    volume: volumeSeries,
    candles: candleSeries,
  };

  function hideSeries(series: "price" | "volume" | "candles") {
    seriesMap[series].applyOptions({ visible: false });
  }
  function showSeries(series: "price" | "volume" | "candles") {
    seriesMap[series].applyOptions({ visible: true });
  }

  function showCandles() {
    hideSeries("price");
    showSeries("candles");
  }
  function hideCandles() {
    showSeries("price");
    hideSeries("candles");
  }
  function showVolume() {
    showSeries("volume");
  }
  function hideVolume() {
    hideSeries("volume");
  }

  function toAmount(v: number): string {
    if (!v) return "";
    return formatAmount({ value: v, currency: currency });
  }

  // Return chart instance
  const ChartInstance = {
    chart,
    priceSeries,
    volumeSeries,
    updatePrice,
    showCandles,
    showVolume,
    hideVolume,
    hideCandles,
  };

  return ChartInstance;
}
