


























































import { Component, Vue } from "vue-property-decorator";
import { formatDate } from "@/core";
@Component({})
export default class ProductDividends extends Vue {
  formatDate(d: string): string {
    return formatDate(d);
  }

  get hasDividends(): boolean {
    return this.divs.upcomingDividend?.payDate || false;
  }

  get dates(): Record<string, string> {
    const { exDate, nnPayDate, payDate } = this.divs.upcomingDividend || {};

    return {
      exDate: exDate ? formatDate(exDate) : "-",
      payDate: payDate ? formatDate(payDate) : "-",
      nnPayDate: nnPayDate ? formatDate(nnPayDate) : "-",
    };
  }

  get divs() {
    const { dividendPerShare, dividendYieldDaily, dividendYieldFiscal } =
      this.$store.state.product.product.fundamentals;

    return {
      dividendPerShare,
      dividendYieldDaily,
      dividendYieldFiscal,
      ...this.$store.state.product.product.dividends,
    };
  }
}
