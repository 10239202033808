



































































import { currencyKey } from "@/core";
import { IAmount } from "@/core/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import MinMaxBar from "../charts/MinMaxBar.vue";
import { formatVolume } from "@/core/utils";
@Component({
  components: {
    MinMaxBar,
  },
})
export default class ProductMarketData extends Vue {
  @Prop() readonly marketData!: {
    assetId: string;
    currency: currencyKey;
    open: number;
    high: number;
    low: number;
    close: number;
    change: number;
    percentChange: number;
    percentChangeFromPreviousClose: number;
    bid: number;
    ask: number;
    mid: number;
    price: number;
    tradingHalted: boolean;
    high52weeks: number;
    low52Weeks: number;
    volume: number;
    averageDailyVolumeLast4Weeks: number;
    percentPriceChange4weeks: number;
    highPriceLast4weeks: number;
    lowPriceLast4weeks: number;
    averageDailyVolumeLast12Months: number;
    percentPriceChange52Weeks: number;
    currentPrice: number;
  };

  getAmt(amt: number): IAmount {
    return {
      value: amt,
      currency: this.marketData.currency,
    };
  }

  vol(v: number): string {
    return formatVolume(v);
  }
}
