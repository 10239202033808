

























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { loadChart, updatePriceFn } from "@/components/charts/LPriceChart";
import { IAmount } from "@/core/types/Portfolio.js";
import {
  ICurrentPrice,
  IFormattedPrice,
  IPricesRes,
} from "@/core/Product/ProductUseCases.js";
import { IChartApi } from "lightweight-charts";
import { MarketStatus } from "@/core";
import HeartIcon from "vue-material-design-icons/CardsHeart.vue";
import HeartOutlineIcon from "vue-material-design-icons/HeartOutline.vue";

export interface IAssetMetadata {
  name: string;
  symbol: string;
  isFav: boolean;
  logoUrl: string;
}

@Component({
  components: {
    HeartIcon,
    HeartOutlineIcon,
  },
})
export default class ProductPricesChart extends Vue {
  @Prop() readonly asset!: IPricesRes;
  @Prop() readonly interval!: "1d" | "1w" | "1m" | "3m" | "1y" | "5y";
  @Prop() readonly assetMetadata!: IAssetMetadata;

  chartElId = `product-price-chart`;
  isHovering = false;
  hoverData = {} as IFormattedPrice;
  updatePrice: updatePriceFn | undefined = undefined;

  mounted(): void {
    const ChartInstance = loadChart({
      id: this.chartElId,
      items: this.asset.items,
      showVolume: this.showVolume,
      showCandles: this.showCandles,
      currency: this.asset.currency,
      interval: this.interval,
    });
    if (this.showVolume) ChartInstance.showVolume();
    if (this.showCandles) ChartInstance.showCandles();

    this.$store.commit("product/SET_CHART_INSTANCE", ChartInstance);
  }

  get hasShares(): boolean {
    const hasShares = this.$store.getters["product/hasShares"];
    return hasShares;
  }

  buy(): void {
    this.$router.push("/comprar/" + this.$route.params.id);
  }
  sell(): void {
    this.$router.push("/vender/" + this.$route.params.id);
  }

  get marketIsOpen(): boolean {
    return MarketStatus.open;
  }

  beforeDestroy(): void {
    this.chart?.remove();
  }

  get chart(): IChartApi | undefined {
    return this.$store.state.product.chart;
  }

  get showVolume(): boolean {
    return this.$store.state.product.showVolume;
  }
  get showCandles(): boolean {
    return this.$store.state.product.showCandles;
  }
  get lastItem(): IFormattedPrice {
    return this.asset.items[this.asset.items.length - 1];
  }
  get intervalData(): {
    priceChange: number;
    percentChange: number;
  } {
    const percentChange =
      this.asset.items[this.asset.items.length - 1].percentChange;
    const priceChange =
      this.asset.items[this.asset.items.length - 1].priceChange;

    return { priceChange, percentChange };
  }

  get showTime(): boolean {
    const intervalsWithTime = ["1d", "1w"];

    return intervalsWithTime.includes(this.interval);
  }

  handleHover({
    detail,
  }: {
    detail: {
      time: number;
      item: IFormattedPrice;
    };
  }): void {
    if (!detail.item) {
      this.isHovering = false;
    } else {
      this.isHovering = true;
      this.hoverData = detail.item;
    }
  }

  toAmount(val: number): IAmount {
    return {
      value: val,
      currency: this.asset.currency,
    };
  }

  // TODO: Move to utils
  formatVolume(v: number): string {
    if (v < 1000) return `${v}`;
    if (v < 1000000) return `${(v / 1000).toFixed(1)}K`;
    if (v < 1000000000) return `${(v / 1000000).toFixed(1)}M`;
    return `${(v / 1000000000).toFixed(1)}B`;
  }

  get currentPrice(): ICurrentPrice {
    return this.$store.state.product.currentPrice;
  }
  get currentPriceAmount(): IAmount {
    return {
      value: this.currentPrice.price,
      currency: this.asset.currency,
    };
  }

  get dataToShow(): Partial<IFormattedPrice> & { formattedDate?: string } {
    if (this.isHovering) {
      return this.hoverData;
    } else {
      // NOT HOVERING
      return {
        value: this.currentPrice.price,
        open: this.currentPrice.open,
        close: this.currentPrice.close,
        low: this.currentPrice.low,
        high: this.currentPrice.high,
        volume: this.lastItem.volume,
        priceChange: this.intervalData.priceChange,
        percentChange: this.intervalData.percentChange,
        formattedDate: this.interval,
      };
    }
  }
}
