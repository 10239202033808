



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MinMaxBar extends Vue {
  @Prop() readonly min!: number;
  @Prop() readonly max!: number;
  @Prop() readonly value!: number;

  hovered = false;

  get dotLeft() {
    if (this.value < this.min) {
      return 0;
    } else if (this.value > this.max) {
      return 100;
    } else {
      return (((this.value - this.min) / (this.max - this.min)) * 100).toFixed(
        2
      );
    }
  }
}
